<script setup>
import { computed, ref, reactive, onMounted, defineProps } from "vue";

const props = defineProps({
  lands: {
    type: Object,
    required: true,
  },
  currentSubscription: {
    type: Object,
    required: true,
  },
});

const screenSize = reactive({
  width: window.innerWidth,
  height: window.innerHeight,
});

const onWindowResize = () => {
  const { innerWidth, innerHeight } = window;
  screenSize.width = innerWidth;
  screenSize.height = innerHeight;
};

const currentLands = computed(() => props.lands || []);
const currentSubscriptionData = computed(
  () => props.currentSubscription || { pricing: {} }
);

const selectedLots = ref([]);

onMounted(() => {
  console.log(props);
  window.addEventListener("resize", onWindowResize);

  const intervalle = setInterval(() => {
    if (window.appCanvas) {
      window.appCanvas.allFields = [...(currentLands.value || [])];
      selectedLots.value = currentLands.value || [];
      clearInterval(intervalle);
    }
  }, 2000);

  // setTimeout(() => {}, 5000)
});
</script>

<template>
  <div>
    <div v-show="!pricingStep && !visitStep">
      <!-- Our vision -->
      <section class="row">
        <div class="col-lg-7">
          <!-- <img src="/assets/images/our-sites/sitemap.jpg" /> -->

          <div
            class="canvas-container p-2 border border-primary rounded-lg relative w-full"
          >
            <canvas
              id="myCanvas"
              :width="
                screenSize.width > 1000
                  ? `${screenSize.width / 2.3}`
                  : `${screenSize.width}`
              "
              height="600"
            ></canvas>

            <div class="controls">
              <button id="zoomInBtn">
                <i class="fas fa-search-plus"></i> Zoom In
              </button>
              <button id="zoomOutBtn">
                <i class="fas fa-search-minus"></i> Zoom Out
              </button>
              <button id="moveLeftBtn">
                <i class="fas fa-arrow-left"></i> Left
              </button>
              <button id="moveUpBtn"><i class="fas fa-arrow-up"></i> Up</button>
              <button id="moveDownBtn">
                <i class="fas fa-arrow-down"></i> Down
              </button>
              <button id="moveRightBtn">
                <i class="fas fa-arrow-right"></i> Right
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="">
            <p
              v-if="!selectedLots.length"
              class="mt-4 flex items-center gap-2 text-[#DB2F2FDE]"
            >
              <img
                class="w-4 h-4"
                src="https://build-together.sagovite.com/assets/icons/info.svg"
              />
              <span class="text-xs md:text-sm">
                Veuillez selectionner un lot pour visualiser
              </span>
            </p>

            <div v-else class="border rounded-md shadow-md px-3 py-3">
              <h3 class="mb-2 font-semibold">Liste des lots</h3>
              <table class="w-100 border rounded-t-sm overflow-hidden">
                <thead
                  class="bg-primary text-white px-3 rounded-t-sm overflow-hidden"
                >
                  <tr>
                    <th class="text-left font-semibold">N°</th>
                    <th class="text-left font-semibold">Bloc</th>
                    <th class="text-left font-semibold">Lot</th>
                    <th class="text-center font-semibold">Superficie</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(land, j) in selectedLots"
                    :key="land.id"
                    :class="{
                      'bg-[#F3F3F3]': j % 2 == 0,
                    }"
                  >
                    <td class="whitespace-nowrap">
                      <NuxtLink
                        to="#"
                        class="text-[#2F80ED] hover:text-primary"
                      >
                        {{ j + 1 }}
                      </NuxtLink>
                    </td>
                    <td class="whitespace-nowrap">
                      {{ land.key.split("__")[0].replace("area", "Bloc ") }}
                    </td>
                    <td class="whitespace-nowrap">
                      {{ land.key.split("__")[1].replace("block", "Lot ") }}
                    </td>

                    <td class="text-center">{{ land.area }}m<sup>2</sup></td>
                  </tr>
                  <tr class="bg-primary text-white font-bold">
                    <td colspan="3" class="whitespace-nowrap">Total</td>

                    <td class="text-center">
                      {{
                        Intl.NumberFormat("fr-FR").format(
                          selectedLots.reduce(
                            (total, item) => total + item.area,
                            0
                          )
                        )
                      }}m<sup>2</sup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="mt-2">
            <table class="table table-striped table-bordered table-hover">
              <!-- <tr>
                <td>Client</td>
                <td>
                  <span>{{ currentSubscriptionData.fullname }}</span
                  ><br />
                  <span>{{ currentSubscriptionData.phone }}</span
                  ><br />
                  <span>{{ currentSubscriptionData.email }}</span>
                </td>
              </tr> -->
              <tr>
                <td>Pack</td>
                <td>
                  {{ currentSubscriptionData.pricing?.months || 0 }}mois ({{
                    currentSubscriptionData.pricing?.m2_price || 0
                  }}FCFA / m<sup>2</sup>)
                </td>
              </tr>
              <tr>
                <td>Montant total</td>
                <td>
                  {{ $formatPrice(currentSubscriptionData.total_amount) }}
                </td>
              </tr>
              <tr>
                <td>Dépôt initial</td>
                <td>
                  {{ $formatPrice(currentSubscriptionData.initial_deposit) }}
                </td>
              </tr>
              <tr>
                <td>Montant versé</td>
                <td>
                  {{ $formatPrice(currentSubscriptionData.current_balance) }}
                </td>
              </tr>
              <tr>
                <td>Reste à payer</td>
                <td>
                  {{ $formatPrice(currentSubscriptionData.remaining_balance) }}
                </td>
              </tr>
              <tr>
                <td>Mensualité</td>
                <td>
                  {{ $formatPrice(currentSubscriptionData.monthly_amount) }}
                </td>
              </tr>
              <tr>
                <td>Date du prochain dépôt</td>
                <td>
                  {{
                    new Date(
                      currentSubscriptionData.next_due_date
                    ).toLocaleString()
                  }}
                </td>
              </tr>
            </table>
          </div>

          <!-- <div class="mt-8 space-y-4 text-[#000000A1] font-bold">
            <div class="d-flex items-center gap-3">
              <div
                style="
                  width: 24px;
                  height: 24px;
                  background-color: #db2f2fde;
                  border-radius: 5px;
                  margin-bottom: 5px;
                "
              ></div>
              <span class="text-sm md:text-base">Lots déja enregistrés</span>
            </div>
          </div> -->
        </div>
      </section>
      <!-- Our vision -->
    </div>
  </div>
</template>

<style scoped>
.controls {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.controls button {
  display: block;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #3498db;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.controls button:hover {
  background-color: #2980b9;
}

/* .canvas-container {
  position: relative;
  width: 800px;
  height: 600px;
} */
</style>

<style scoped>
table thead th {
  height: 40px;
  padding-left: 20px;
}

table tbody td {
  height: 50px;
  padding-left: 20px;
}
</style>
